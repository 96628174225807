import React from "react"

const PageTitle = ({ title, h1 }) => {
  if (h1) {
    return (
      <div className="page-title">
        <h1 className="page-title__t">
          <span className="page-title__in">{title}</span>
        </h1>
      </div>
    )
  } else {
    return (
      <div className="page-title">
        <p className="page-title__t">
          <span className="page-title__in">{title}</span>
        </p>
      </div>
    )
  }
}

export default PageTitle
