import React from "react"
import Layout from "../components/common/layout"
import Meta from "../components/common/meta"
import PageTitle from "../components/pageTitle/pageTitle"
import { Link } from "gatsby"

const recruitPage = () => (
  <Layout page="recruit">
    <Meta
      title="recruitment | 1moku co."
      description="hirofumi sugaを中心に庭園デザインをはじめ、庭師の視点でとらえた空間デザイン、ランドスケープデザインなどを手掛けている1moku co.の採用情報についてご紹介します。"
      url="https://www.1moku.co.jp/recruit/"
      type="article"
    />
    <PageTitle title="recruitment" />
    <div className="container">
      <div className="container__head" data-scroll="">
        <h1 className="container__title" data-scroll="">
          <span className="en">recruitment</span>
          <span className="ja">採用情報</span>
        </h1>
      </div>
      <div className="container__body">
        <p className="container__text en" data-scroll="">
          We are currently recruiting workers in the following categories:
          People with abilities in garden planning, design, or management People
          who can produce CAD drawings and give presentations. People with an
          interest in enhancing their skills in VectorWorks / Illustrator /
          Photoshop / O-seven (exterior CAD).
          <br />
          Please contact us if you are interested.
        </p>
        <p className="container__text ja" data-scroll="">
          庭の設計・デザイン・監理の出来る方、CAD作図、プレゼンテーションの出来る方、
          <br />
          （VectorWorks / Illustrator / Photoshop / オーセブン（外構CAD））
          向上心のある外注の庭師さん。
          <br />
          興味のある方は一度ご連絡ください。
        </p>
        <div className="container__btn btn en" data-scroll="">
          <Link className="btn__link" to="/contact">
            Contact
          </Link>
        </div>
      </div>
    </div>
  </Layout>
)

export default recruitPage
